<template>
  <div id="app">
    <h1>PMWork Webmail Client</h1>
    <button @click="fetchEmails">Fetch Emails</button>
    <ul>
      <li v-for="email in emails" :key="email.id">
        <strong>{{ email.to }}</strong>: {{ email.subject }}
      </li>
    </ul>
    <form @submit.prevent="sendEmail">
      <input v-model="newEmail.to" placeholder="To" required />
      <input v-model="newEmail.subject" placeholder="Subject" required />
      <textarea v-model="newEmail.text" placeholder="Message" required></textarea>
      <button type="submit">Send Email</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      emails: [],
      newEmail: {
        to: '',
        subject: '',
        text: '',
      },
    };
  },
  methods: {
    fetchEmails() {
      axios.get('https://vps.immdly.com:3000/api/emails')
        .then(response => {
          this.emails = response.data;
        })
        .catch(error => {
          console.error("Error fetching emails:", error);
        });
    },
    sendEmail() {
      axios.post('https://vps.immdly.com:3000/api/send', this.newEmail)
        .then(response => {
          alert('Email sent successfully!');
          this.newEmail = { to: '', subject: '', text: '' };
        })
        .catch(error => {
          console.error("Error sending email:", error);
        });
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
